import { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { InteractiveHoverButton } from "./InteractiveButton";
import desktopDark from "../../assets/desktopDark.png";

function Hero() {
  const [titleNumber, setTitleNumber] = useState(0);
  const titles = useMemo(
    () => ["secure.", "fast.", "integrated.", "CoverAi."],
    []
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (titleNumber === titles.length - 1) {
        setTitleNumber(0);
      } else {
        setTitleNumber(titleNumber + 1);
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [titleNumber, titles]);

  return (
    <div className="w-full font-montserrat">
      <div className="container mx-auto">
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex gap-8 py-20 z-20 lg:py-40 items-left justify-center flex-col max-w-2xl">
            <div className="flex gap-4 flex-col">
              <h1 className="text-5xl md:text-7xl tracking-tighter text-left">
                <span className="font-thin">Choose</span>
                <span className="relative overflow-hidden text-left md:pb-4 md:pt-1">
                  &nbsp;
                  {titles.map((title, index) => (
                    <motion.span
                      key={index}
                      className="absolute font-bold"
                      initial={{ opacity: 0, y: "-100" }}
                      transition={{ type: "spring", stiffness: 50 }}
                      animate={
                        titleNumber === index
                          ? {
                              y: 0,
                              opacity: 1,
                            }
                          : {
                              y: titleNumber > index ? -150 : 150,
                              opacity: 0,
                            }
                      }
                    >
                      {title}
                    </motion.span>
                  ))}
                </span>
              </h1>

              <p className="text-lg md:text-xl leading-relaxed tracking-tight text-muted-foreground text-left">
                Protecting your business's sensitive information is paramount.
                Avoid the risks associated with cloud models and current AI
                providers. Our secure offline and private cloud ChatGPT
                alternative empowers your team with advanced AI capabilities,
                ensuring privacy and reliability without compromise.
              </p>
            </div>
            <div className="flex flex-row gap-3 items-left">
              <InteractiveHoverButton text="Join Us" className="gap-12 border border-white hover:bg-white hover:text-black" />
            </div>
          </div>
          
          <div className="hidden lg:block z-10 absolute -right-72 top-1/2 -translate-y-1/2 w-3/4">
            <img src={desktopDark} alt="Desktop Application" className="w-full h-auto object-cover"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;