import React from 'react';
import logo from '../assets/CoverAILogoWhite.png';
import microsoft from '../assets/microsoft.png';
import nvidia from '../assets/nvidia.png';
import thinkon from '../assets/thinkon.png';
import write from '../assets/write.png';
import lock from '../assets/lock.png';
import page from '../assets/page.png';
import WaterDropGrid from './WaterDropGrid';
import PoolsideSection from './PoolsideSection';
import LandingPage from './PoolsideSectionTwo';
import PoolsideSectionThree from './PoolsideSectionThree';
import PoolsideSectionFour from './PoolsideSectionFour';
import Hero from './OpenDoor/Hero';

const MainPage = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col justify-center font-sans relative">
      <img src={logo} alt="CoverAI Logo" className="w-32 mx-8 mt-8" />
      <div className="hidden sm:block absolute top-32 right-0 left-0 lg:right-8 lg:left-auto ">
        <div className="lg:w-1/2 h-full ">
          <WaterDropGrid />
        </div>
      </div>
      {/* Main Content Area */}
      <div className="p-8 lg:p-32 pt-0 lg:pt-0">
        <div className="max-w-3xl relative z-10">
          <div className="flex-1 h-screen flex justify-center items-center text-center sm:text-left sm:items-baseline pt-8 sm:pt-32">
            {/* Section 1 */}
            <div className="w-full max-w-4xl px-8">
              {/* Main Text Section */}
              <div className="space-y-8">
                <div className="space-y-4">
                  <h1 className="text-4xl sm:text-5xl font-extrabold leading-snug">
                    Safely <span className="bg-white/10 border border-white text-nowrap">Unlock AI</span> Within Your Business
                  </h1>
                  <p className='text-[#AEAEAE]'>
                    CoverAI provides custom solutions for Midmarket firms looking to empower their business with compliant AI systems.
                  </p>
                </div>
              </div>
              {/* Right Background Area with WaterDropGrid */}
            </div>
          </div>

          <div className="space-y-16">
            {/* Section 2 - About */}
            <section className="space-y-4">
              <div className="flex items-center space-x-2">
                <img src={lock} alt="Lock Icon" className="w-4 h-4" />
                <h1 className="font-mono text-white uppercase">Full Compliance</h1>
              </div>
              <p className="text-base text-[#AEAEAE]">
                We handle the most sensitive data, regardless of your industry's regulations. Our AI solutions ensure GDPR and HIPAA compliance, keeping data secure on-premises and meeting ISO/IEC 27001 and SOC 2 standards.
              </p>
            </section>

            {/* Section 3 - Custom Training */}
            <section className="space-y-4">
              <div className="flex items-center space-x-2">
                <img src={page} alt="Page Icon" className="w-4 h-4" />
                <h1 className="font-mono text-white uppercase">Custom Training</h1>
              </div>
              <p className="text-base text-[#AEAEAE]">
                We provide custom, industry-specific AI training, ensuring precise, useful results. Traditional models often lack value when untrained, as their broad context window fails to capture specialized nuances unique to your business.
              </p>
            </section>

            {/* Section 4 - Effortless Use */}
            <section className="space-y-4">
              <div className="flex items-center space-x-2">
                <img src={write} alt="Write Icon" className="w-4 h-4" />
                <h1 className="font-mono text-white uppercase">Effortless Use</h1>
              </div>
              <p className="text-base text-[#AEAEAE]">
                We simplify AI adoption for your team, enhancing productivity intuitively. Our optimized LLMs blend robust features with the user-friendly experience of familiar models, delivering an experience your team will actually look forward to.
              </p>
            </section>
          </div>
        </div>

        {/* Section 5 - Trusted Brands */}
        <div className="flex flex-col justify-center items-center space-y-6 py-40">
          <p>Expertise from brands you trust.</p>
          <div className="flex justify-center space-x-12">
            <img src={microsoft} alt="Microsoft Logo" className="w-24" />
            <img src={nvidia} alt="Nvidia Logo" className="w-24" />
            <img src={thinkon} alt="ThinkOn Logo" className="w-24" />
          </div>
        </div>

        {/* Section 6 - Hosting Solutions */}
        <div className='border border-[#AEAEAE] rounded p-8'>
          <div className='space-y-4 max-w-lg'>
            <h1 className='text-white text-2xl'>
              A full selection of On-Premises, Edge, and Cloud solutions.
            </h1>
            <p className='text-[#AEAEAE]'>
              We offer flexible AI hosting solutions—on-premises, edge, or cloud—tailored to your needs. Using the latest Nvidia technology and industry standards, we ensure scalable, high-performance deployments for any environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MainPage2 = () => { 
  return (
    <div>
      <PoolsideSection />
    </div>
  )
};

const MainPage3 = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col justify-center relative">
      <LandingPage />
    </div>
  )
};

const MainPage4 = () => {
  return (
    <div className="bg-[#1c1c1c] text-white min-h-screen flex flex-col justify-center relative">
      <PoolsideSectionThree />
    </div>
  )
};

const MainPage5 = () => {
  return (
    <div className="bg-[#1c1c1c] text-white min-h-screen flex flex-col justify-center relative">
      <PoolsideSectionFour />
    </div>
  )
};

const MainPage6 = () => {
  return (
    <div className="bg-black text-white min-h-screen flex flex-col justify-center relative">
      <Hero />
    </div>
  )
};

export { MainPage, MainPage2, MainPage3, MainPage4, MainPage5, MainPage6 };
