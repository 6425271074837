import * as React from "react"
import { ArrowRight } from "lucide-react";
import { cn } from "../../lib/utils";

interface InteractiveHoverButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
}

const InteractiveHoverButton = React.forwardRef<
  HTMLButtonElement,
  InteractiveHoverButtonProps
>(({ text = "Button", className, ...props }, ref) => {
  const [isInput, setIsInput] = React.useState(false);
  const [email, setEmail] = React.useState("");
  
  const handleSubmit = async () => {
    if (!email) return;
    try {
      
      console.log("Submitting email:", email);
      setIsInput(false);
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      if (response.ok) {
        setEmail("");
      }
    } catch (error) {
      console.error('Failed to subscribe:', error);
    }
  };

  return (
    !isInput ? (
    <button
      ref={ref}
      onClick={() => !isInput && setIsInput(true)}
      className={cn(
        "group relative min-w-[250px] cursor-pointer overflow-hidden rounded-lg border bg-background p-2 text-center font-semibold",
        className,
      )}
      {...props}
    >
      <span className="inline-block translate-x-1 transition-all duration-300 group-hover:translate-x-12 group-hover:opacity-0">
        {text}
        </span>
        <div className="absolute left-[20%] top-[40%] h-2 w-2 scale-[1] rounded-lg bg-white transition-all duration-300 group-hover:left-[0%] group-hover:top-[0%] group-hover:h-full group-hover:w-full group-hover:scale-[1.8] group-hover:bg-white">
        </div>
        <div 
        className="absolute top-0 z-10 flex h-full w-full translate-x-12 items-center justify-center gap-2 opacity-0 transition-all duration-300 group-hover:-translate-x-1 group-hover:opacity-100"
        onClick={isInput ? handleSubmit : undefined}
      >
        <ArrowRight />
      </div>
    </button>
    ) : (
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
          placeholder="Enter your email"
          className="min-w-[250px] cursor-pointer overflow-hidden rounded-lg border bg-background p-2 text-center font-semibold bg-transparent outline-none text-center"
          onClick={(e) => e.stopPropagation()}
        />
    )   
  );
});

InteractiveHoverButton.displayName = "InteractiveHoverButton";

export { InteractiveHoverButton };
