import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MainPage2, MainPage3, MainPage4, MainPage6 } from './components/MainPage.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage6 />} />
        <Route path="/v2" element={<MainPage2 />} />
        <Route path="/v3" element={<MainPage3 />} />
        <Route path="/v4" element={<MainPage4 />} />
        <Route path='/v6' element={<MainPage6 />} />
      </Routes>
    </Router>
  );
}

export default App;
